<template>
  <div class="footer">
    <div class="web">
      <div class="top clearfix">
        <div class="top-L">
          <h5>友情链接</h5>
          <ul class="top-list clearfix">
            <li>
              <a href="http://jyj.changsha.gov.cn/" target="_blank">长沙市教育局</a>
            </li>
            <li>
              <a href="http://www.hnedu.cn/" target="_blank">湖南省教育大平台</a>
            </li>
            <li>
              <a href="http://jyt.hunan.gov.cn/" target="_blank">湖南省教育厅</a>
            </li>
            <li>
              <a href="http://lh.wenming.cn/" target="_blank">中国文明网</a>
            </li>
            <li>
              <a href="http://www.scs.gov.cn/" target="_blank">国家公务员局</a>
            </li>
            <li>
              <a href="http://cpc.people.com.cn/" target="_blank">中国共产党新闻网</a>
            </li>
            <li>
              <a href="http://www.gov.cn/" target="_blank">中国政府网</a>
            </li>
            <li>
              <a href="http://www.mohrss.gov.cn/" target="_blank">人力资源社会保障部</a>
            </li>
            <li>
              <a href="http://www.cpta.com.cn/" target="_blank">中国人事考试网</a>
            </li>
            <li>
              <a href="http://www.gxscse.com/" target="_blank">思而学教育网站</a>
            </li>
          </ul>
        </div>
        <div class="top-R">
          <H5>关于我们</H5>
          <div class="top-ab clearfix">
            <div class="info">
              <p>扫描二维码</p>
              <p>关注公众号查看详情</p>
            </div>
            <div class="img">
              <img src="../assets/images/ewm.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="mid clearfix">
        <div class="mid-L">
          <div class="footer-nav">
            <a href="javascript:;">关于我们</a>
            <a href="javascript:;">产品下载</a>
            <a href="javascript:;">线下活动</a>
            <a href="javascript:;">师资介绍</a>
            <a href="javascript:;">商务合作</a>
            <a href="javascript:;">走进小书丁</a>
          </div>
          <p class="footer-add">公司地址：湖南省长沙市雨花区德思勤城市广场A7栋</p>
        </div>
        <div class="mid-R clearfix">
          <div class="weibo">
            <a href="javascript:;"><img src="../assets/images/weibo.png" alt="" /></a>
            <p>新浪微博</p>
          </div>
          <div class="wechat">
            <a href="javascript:;"><img src="../assets/images/wechat.png" alt="" /></a>
            <p>微信公众号</p>
          </div>
        </div>
      </div>
      <div class="bot">
        <p> Copyright ©2019-2021 www.shudingkj.com All Rights Reserved</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'readerFooter'
};
</script>

<style>
</style>
<template>
  <div>
      <ReaderHeader></ReaderHeader>
    <div class="dwrap" v-loading="loading">
      <div class="detail-show web">
        <div class="top">
          <h5><router-link :to="{path:'/'}" tag="a">{{datalist.title}}</router-link></h5>
        </div>
        <div class="detail-main">
          <div class="main-top">
            <h5 class="title">{{datalist.subtitle}}</h5>
            <div class="top-smTitle clearfix">
              <span>发布时间：{{datalist.addTime}}</span>
            </div>
          </div>
          <div class="main-bot">
            <div class="img">
              <img :src="datalist.bannel" alt="" />
            </div>
            <div class="detail-info">
              <h5>{{datalist.subtitle}}</h5>
              <div class="info-item">
                  <div class="item">
                      <h5>一、负责老师</h5>
                      <div class="info">
                        <p class="name">负责老师：{{datalist.teacherName}}</p>
                        <p class="phone">电话：{{datalist.teacherMobile}}</p>
                      </div>
                  </div>
                   <div class="item">
                      <h5>二、校园地址</h5>
                      <div class="info">
                        <p class="add">校园地址：{{datalist.address}}</p>
                      </div>
                  </div>
              </div>
              <div class="content-info">
                  <div class="infolist" v-html="datalist.content" style="white-space: pre-wrap;">
                  </div>
                  <div class="img">
                      <img :src="datalist.details" alt="" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReaderFooter></ReaderFooter>
  </div>
</template>

<script>
import ReaderHeader from "@/components/readerHeader";
import ReaderFooter from "@/components/readerFooter";
export default {
  name: "detail",
  data(){
      return {
        detailId:'',
        datalist:{},
        loading:true,
      }
  },
  components: {
    ReaderHeader,
    ReaderFooter,
  },
  created(){
     this.detailId=this.$route.query.Id;
      this.getData();
  },
  methods:{
        getData() {
       this.$axios({
         method: "post",
         params:{
             activityId:this.detailId,
         },
         url: "/teacher/activity/info",
       }).then((res) => {
           this.loading=false;
           console.log(res.data.data.activity)
         this.datalist= res.data.data.activity;
       });
    },
  }
};
</script>

<style>
</style>
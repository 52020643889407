<template>
  <div class="content-wrap" v-loading="loading">
    <reader-header></reader-header>
    <!-- banner -->
    <div class="banner" id="banner">
      <div class="video-box web">
        <!-- <a href="javascript:;" class="video-btn"></a> -->
        <reader-video></reader-video>
      </div>
    </div>
    <!-- about -->
    <div class="about" id="about">
      <div class="web">
        <div class="about-title common-title">关于我们</div>
        <div class="about-info">
          <p>
            湖南书丁网络科技有限公司，是一家秉持全国先进教育管理理念，整合湖南优质名师资源，专注每位学生能力开发与培养的个性化教育公司。
          </p>
          <br />
          <p>
            公司坚持以“用心做教育、用爱筑未来”的思想为行动指引，致力于为小学至高三学生提供高品质的文化课及五大学科竞赛辅导。
          </p>
          <br />
          <p>
            公司以精品小班和一对一辅导为主，分层次讲解，针对性强。近几年我们每年均可向四大名校输送两百多名高一新生，他们在全国各类学科竞赛中亦频获嘉奖，
            有的优秀学生在高二就保送清华、北大，诸多成绩使得公司成为本土美誉度极高的教育品牌之一。
          </p>
        </div>
      </div>
    </div>

    <!-- product -->

    <div class="product" id="product">
      <div class="web">
        <div class="pro-wrap">
          <div class="pro-title common-title">产品下载</div>
          <div class="pro-logo">
            <img src="../assets/images/pro_logo.png" alt="" />
          </div>
          <h5 class="sm-title">小学、初中、高中——高品质文化课辅导</h5>
          <a href="javascript:;" class="download-btn" @click="download"
            >立即下载</a
          >
          <div class="proImg-wrap">
            <ul class="pro-list clearfix">
              <li>
                <a href="javascript:;">
                  <img src="../assets/images/pro1.png" alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <img src="../assets/images/pro2.png" alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <img src="../assets/images/pro3.png" alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <img src="../assets/images/pro4.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- activity -->
    <div class="activity" id="activity">
      <div class="web">
        <div class="act-title common-title">线下活动</div>
        <div class="act-show">
          <ul class="act-list clearfix">
            <li v-for="item in dataList" :key="item.id">
              <router-link :to="{ name: 'detail', query: { Id: item.id } }">
                <div class="img">
                  <img :src="item.bannel" alt="" />
                </div>
                <div class="info">
                  <p>{{ item.title }}</p>
                </div>
              </router-link>
            </li>
          </ul>
          <!-- <a href="javascript:;" class="more-btn">查看更多></a> -->
        </div>
      </div>
    </div>

    <!-- teachers -->
    <div class="teachers" id="teachers">
      <div class="web">
        <div class="teacher-title common-title">师资介绍</div>
        <div class="teacher-show">
          <ul class="teacher-list clearfix">
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/teacher_1.png" alt="" />
                </div>
                <div class="text-info">
                  <h5>弘慧伟</h5>
                  <p>语文老师、12年经验</p>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/teacher_2.png" alt="" />
                </div>
                <div class="text-info">
                  <h5>养海婉</h5>
                  <p>英语老师、名校毕业</p>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/teacher_3.png" alt="" />
                </div>
                <div class="text-info">
                  <h5>杨东</h5>
                  <p>数学老师、经验丰富</p>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/teacher_4.png" alt="" />
                </div>
                <div class="text-info">
                  <h5>张静</h5>
                  <p>数学老师、经验丰富</p>
                </div>
              </a>
            </li>
          </ul>
          <!-- <a href="javascript:;" class="more-btn">查看更多></a> -->
        </div>
      </div>
    </div>

    <!-- reader -->
    <div class="reader" id="reader">
      <div class="web">
        <div class="reader-title common-title">线下活动</div>
        <div class="reader-show">
          <ul class="reader-list clearfix">
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/reader_1.png" alt="" />
                </div>
                <div class="info">
                  <h5>舒适校区</h5>
                  <p>长沙市雨花区韶山中路东塘服装交易大楼二楼</p>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/reader_2.png" alt="" />
                </div>
                <div class="info">
                  <h5>舒适校区</h5>
                  <p>岳麓区洋湖和园商业中心二楼</p>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/reader_3.png" alt="" />
                </div>
                <div class="info">
                  <h5>舒适校区</h5>
                  <p>南雅中学路口楠雅宾馆二楼</p>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/reader_4.png" alt="" />
                </div>
                <div class="info">
                  <h5 class="center">优质好评</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/reader_5.png" alt="" />
                </div>
                <div class="info">
                  <h5 class="center">优质好评</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <div class="img">
                  <img src="../assets/images/reader_6.png" alt="" />
                </div>
                <div class="info">
                  <h5 class="center">优质好评</h5>
                </div>
              </a>
            </li>
          </ul>
          <!-- <a href="javascript:;" class="more-btn">查看更多></a> -->
        </div>
      </div>
    </div>

    <!-- cooperation -->
    <div class="cooperation" id="cooperation">
      <div class="web">
        <div class="title">期待与有志之士真诚合作 共创未来</div>
        <div class="tel">
          <span><img src="../assets/images/tel_icon.png" alt="" /></span>
          <h5>18673170274</h5>
        </div>
        <p class="add">—— 湖南省长沙市雨花区德思勤城市广场A7栋2009 ——</p>
      </div>
    </div>
    <!-- app -->
    <div class="app" id="app">
      <div class="web">
        <h3>小书丁APP</h3>
        <h5>全科学习，难点全掌握</h5>
        <a href="javascript:;" class="download-btn" @click="download"
          >立即体验</a
        >
      </div>
    </div>
    <reader-footer></reader-footer>
  </div>
</template>

<script>
import ReaderHeader from "@/components/readerHeader";
import ReaderFooter from "@/components/readerFooter";
import ReaderVideo from "@/components/Video";
export default {
  name: "Home",
  components: {
    ReaderHeader,
    ReaderFooter,
    ReaderVideo,
  },
  data() {
    return {
      dataList: [],
      loading:true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    download() {
      this.$router.push({ name: "download" });
    },
    getData() {
      this.$axios({
        method: "post",
        url: "/teacher/activity/list",
      }).then((res) => {
        this.loading=false;
        this.dataList = res.data.data.activity;
      });
    },
  },
};
</script>

<template>
  <div>
    <ReaderHeader></ReaderHeader>
    <div class="download">
      <div class="main web clearfix">
        <div class="img">
          <img src="../assets/images/down_img.png" alt="" />
        </div>
        <div class="right-main">
          <div class="logo">
            <img src="../assets/images/dlogo.png" alt="" />
          </div>
          <p>全科学习，难点全掌握,你想要的这里都有</p>
          <div class="downloadBtn">
            <a href="javascript:;" class="ios"> IOS下载 </a>
            <a href="javascript:;" class="anzhuo"> Android下载 </a>
          </div>
          <div class="ewm-list clearfix">
            <div class="img">
              <img src="../assets/images/ewm2.png" alt="" />
            </div>
            <div class="img">
              <img src="../assets/images/ewm1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReaderFooter></ReaderFooter>
  </div>
</template>

<script>
import ReaderHeader from "@/components/readerHeader";
import ReaderFooter from "@/components/readerFooter";
export default {
  name: "download",
  components: {
    ReaderHeader,
    ReaderFooter,
  },
};
</script>

<style>
</style>
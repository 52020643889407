<template>
    <header class="header">
	<div class="web clearfix">
		<div class="logo">
			<router-link :to="{path:'/'}" tag="a"><img src="../assets/images/logo.png" alt=""></router-link>
		</div>
		<ul class="nav">
			<li :class="{'active':index==0}"><router-link :to="{path:'/'}" tag="a">首页</router-link></li>
			<li :class="{'active':index==1}"><a href="javascript:;" @click="changeIndex(1)" v-scroll-to="'#about'">关于我们</a></li>
			<li :class="{'active':index==2}"><a href="javascript:;" @click="changeIndex(2)" v-scroll-to="'#product'">产品下载</a></li>
			<li :class="{'active':index==3}"><a href="javascript:;" @click="changeIndex(3)" v-scroll-to="'#activity'">线下活动</a></li>
			<li :class="{'active':index==4}"><a href="javascript:;" @click="changeIndex(4)" v-scroll-to="'#teachers'">师资介绍</a></li>
			<li :class="{'active':index==6}"><a href="javascript:;" @click="changeIndex(6)" v-scroll-to="'#reader'">走进小书丁</a></li>
			<li :class="{'active':index==5}"><a href="javascript:;" @click="changeIndex(5)" v-scroll-to="'#cooperation'">商务合作</a></li>
			
		</ul>
		<a href="http://teacher.shudingkj.com" target="_blank" class="login-btn">教师登录</a>
	</div>
</header>
</template>

<script>
export default {
	name:'readerHeader',
	data(){
		return{
			index:0
		}
	},
	methods:{
		changeIndex(index){
			this.index=index;
		}
	}
}
</script>

<style>

</style>
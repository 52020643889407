import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueScrollTo from "vue-scrollto";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VideoPlayer from 'vue-video-player'

if (process.env.NODE_ENV == 'development') {//开发环境
  axios.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'production') {//生产环境
  axios.defaults.baseURL = 'http://teacherapi.shudingkj.com/';
}

axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$axios=axios;

Vue.use(VueScrollTo, options);
let options = {
  container: "body", //滚动的容器
  duration: 500, //滚动时间
  easing: "ease", //缓动类型
  offset: 0, //滚动时应应用的偏移量。此选项接受回调函数
  force: true, //是否应执行滚动
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
  };
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.use(ElementUI);
import './assets/css/layout.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
